#home {
    width: 100%;
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
            160deg,
            rgba(26, 115, 232, 1) 30%,
            rgba(120, 178, 255, 1) 100%
        );
        padding: 20px;
        min-height: 250px;

        article {
            color: white;
            min-width: 600px;

            h1 {
                font-size: 26px;
                letter-spacing: 0.5px;
                font-weight: 600;
            }

            p {
                font-size: 16px;
                letter-spacing: 0.5px;
                font-weight: 400;
            }
        }
    }

    .content {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        padding: 20px;
    }
}
