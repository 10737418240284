@import '../../variables';

.mainContainer {
    display: none;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    gap: 10px;
    position: absolute;
    background-color: white;

    &.visible {
        display: flex;
    }

    p {
        user-select: none;
    }

    .selectionContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    .icon {
        width: 15px;
    }

    .listContainer {
        width: 40px;
        height: 40px;
        overflow: hidden;
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(transparent 10px, white);
        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(white, transparent 10px);
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        width: 40px;
        transition: margin-top 0.2s;

        li {
            display: flex;
            height: 20px;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            user-select: none;

            &:first-child {
                margin-top: 10px;
            }
        }
    }

    .confirmButton {
        height: 36px;
        padding: 0 15px;
        border-radius: 4px;
        background-color: $main-color;
        outline: none;
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        user-select: none;

        &:hover {
            cursor: pointer;
        }
    }
}
