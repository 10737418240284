@import '../../variables';

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    justify-content: center;
    align-items: center;

    &.shown {
        display: flex;
    }

    .modal {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        height: fit-content;
        color: $font-color;
        min-width: 400px;

        header {
            display: flex;
            height: 40px;

            h3 {
                font-size: 16px;
                letter-spacing: 0.5px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;

            .row {
                display: flex;
                border-bottom: 1px solid #ddd;
                padding: 10px 0;

                .col {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                h4 {
                    font-size: 14px;
                    color: #666;
                }

                p {
                    color: #666;
                    font-size: 14px;
                }

                .icon {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        footer {
            display: flex;
            justify-content: right;
            margin-top: 20px;
            gap: 10px;

            button {
                height: 40px;
                border-radius: 4px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                font-weight: 600;
                outline: none;
                border: none;
                letter-spacing: 1px;
                font-size: 12px;

                &:hover {
                    cursor: pointer;
                }

                &.positive {
                    background-color: $main-color;
                    color: white;
                }

                &:disabled {
                    background-color: #eee;
                    color: #666;
                    cursor: not-allowed;
                }

                &.negative {
                    background: none;
                    color: #666;

                    &:hover {
                        background-color: #eee;
                    }
                }
            }
        }
    }
}
