@import '../../variables';

#loginView {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    header {
        width: 100%;
        height: 33vh;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
            160deg,
            rgba(26, 115, 232, 1) 30%,
            rgba(120, 178, 255, 1) 100%
        );
    }

    form {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: fit-content;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        margin-top: 100px;
        min-width: 300px;
        z-index: 1;
        background-color: white;

        h2 {
            color: $font-color;
        }

        .input {
            margin-top: 20px;
        }

        .errorMessage {
            margin-top: 20px;
            font-size: 12px;
            color: $error-dark-color;
            background: $error-light-color;
            display: none;
            height: 30px;
            border: 1px solid $error-dark-color;
            align-items: center;
            border-radius: 4px;
            letter-spacing: 0.2px;
            padding: 0 10px;

            &.shown {
                display: flex;
            }
        }

        button {
            height: 46px;
            background-color: $main-color;
            border-radius: 4px;
            padding: 0 15px;
            color: white;
            outline: none;
            border: none;
            font-size: 13px;
            margin-top: 40px;

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                background-color: #eee;
                color: #999;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }
}
