@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins;
}

body {
    width: 100%;
    height: 100%;
}
