@import '../../variables';

.mainContainer {
    width: 230px;
    min-height: 150px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: none;
    position: absolute;
    top: 26px;
    left: 10px;
    padding: 10px;
    flex-direction: column;

    &.visible {
        display: flex !important;
    }

    header {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        height: 25px;
        gap: 10px;

        h3 {
            font-size: 14px;
            color: $font-color;
            user-select: none;
        }

        img {
            height: 16px;

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                position: absolute;
                right: 0;
            }
        }
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;

        &:hover {
            cursor: pointer;
        }
    }

    .daysOfWeekList {
        display: flex;
        list-style: none;
        font-size: 12px;
        justify-content: space-between;
        width: 100%;
        height: fit-content;

        li {
            color: #999;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 25px;
            user-select: none;
        }
    }

    .daysContainer {
        display: flex;
        flex-wrap: wrap;

        .dayItem {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            font-size: 12px;
            border-radius: 3px;
            user-select: none;

            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.07);
            }

            &.notAllowed {
                color: #ccc;
            }
        }
    }
}
