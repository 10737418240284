@import '../../variables';

.container {
    display: flex;
    flex-direction: column;

    label {
        display: flex;
        height: 30px;
        align-items: center;
        font-size: 13px;
        color: #666;
    }

    input,
    textarea {
        border: 1px solid #ccc;
        border-radius: 3px;
        height: 36px;
        padding-left: 5px;
        outline: none;
        color: $font-color;

        &:focus {
            outline: 2px solid $main-color;
        }
    }

    textarea {
        padding-top: 5px;
        resize: none;
        min-height: 70px;
    }

    .inputLength {
        font-size: 11px;
        margin-top: 4px;
        text-align: end;
    }
}
