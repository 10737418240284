@import '../../variables';

#pushNotifications {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    header.viewHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
            160deg,
            rgba(26, 115, 232, 1) 30%,
            rgba(120, 178, 255, 1) 100%
        );
        padding: 20px;
        min-height: 250px;

        article {
            color: white;
            min-width: 600px;

            h3 {
                font-size: 30px;
                letter-spacing: 0.5px;
                font-weight: 600;
            }

            p {
                font-size: 16px;
                letter-spacing: 0.5px;
                font-weight: 400;
            }
        }
    }

    section.body {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;

        form {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            padding-top: 15px;
            color: $font-color;
            min-width: 600px;

            section.step {
                display: flex;
                flex-direction: column;

                header.stepHeader {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    gap: 15px;

                    .stepCounter {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: $main-color;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;
                    }

                    h3 {
                        font-size: 16px;
                        letter-spacing: 0.5px;
                    }
                }
            }

            .stepContent {
                margin-left: 32px;
                padding: 0 25px;
                border-left: 1px solid #ddd;
            }

            .inputContainer {
                margin-bottom: 15px;
                max-width: 320px;
            }

            .sectionDescription {
                display: block;
                font-size: 13px;
                color: #666;
                margin-bottom: 10px;
            }

            footer {
                border-top: 1px solid #ddd;
                padding: 20px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                button {
                    height: 36px;
                    background-color: $main-color;
                    border-radius: 4px;
                    padding: 0 15px;
                    color: white;
                    outline: none;
                    border: none;
                    font-size: 13px;

                    &:hover {
                        cursor: pointer;
                    }

                    &:disabled {
                        background-color: #eee;
                        color: #999;

                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }

    .alertDialog {
        width: fit-content;
        max-width: 600px;
        padding: 0 20px;
        height: 36px;
        border-radius: 4px;
        background-color: #ddd;
        border: 1px solid #999;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -50px;
        left: 50%;
        font-size: 14px;
        transform: translateX(-50%);
        transition: visibility 0.1s, bottom 0.5s;
        visibility: hidden;

        &.success {
            background-color: #c4ffd1;
            border: 1px solid #0ab819;
            color: #0ab819;
        }

        &.error {
            background-color: #ffc4c4;
            border: 1px solid #b80a0a;
            color: #b80a0a;
        }

        &.shown {
            visibility: visible;
            bottom: 20px;
        }
    }
}
