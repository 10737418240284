@import '../../variables';

.container {
    position: relative;
    display: flex;
    gap: 10px;

    input {
        border: 1px solid #ccc;
        border-radius: 3px;
        outline: none;
        padding-left: 5px;
        height: 36px;

        &:focus {
            outline: 2px solid $main-color;
        }
    }

    .dateInput {
        width: 120px;
    }

    .timeInput {
        width: 50px;
    }
}
