.serviceCard {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    width: 300px;
    height: fit-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    transition: box-shadow 0.3s;

    &.disabled {
        img {
            filter: grayscale(1);
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    section {
        padding: 15px;

        h3 {
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 0.5px;
            margin-bottom: 5px;
        }

        p {
            font-size: 13px;
            color: #666;
            font-weight: 300;
        }
    }
}
