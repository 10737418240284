#menu {
    height: 100vh;
    width: 68px;
    background-color: #051e34;
    display: flex;
    flex-direction: column;
    transition: width 0.25s ease-out;
    position: sticky;
    top: 0;
    left: 0;

    &.expanded {
        width: 280px;

        footer {
            padding: 0 15px;
            .toggleIcon {
                transform: rotate(0);
            }
        }

        ul li span {
            opacity: 1;
            visibility: visible;
        }
    }

    header,
    footer {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        color: white;
        padding: 0 24px;
    }

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;

        li {
            height: 40px;
            font-size: 14px;
            user-select: none;

            &:hover {
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.2);
            }

            a {
                text-decoration: none;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 0 24px;
                color: white;
                gap: 10px;
                user-select: none;
            }

            span {
                min-width: 150px;
                transition: opacity 0.25s, visibility 0.2s;
                opacity: 0;
                visibility: hidden;
            }

            svg {
                min-width: 20px;
            }

            &.logout {
                width: 100%;
                color: white;
                display: flex;
                align-items: center;
                padding: 0 20px;
                position: absolute;
                bottom: 0;
                gap: 10px;
            }
        }
    }

    footer {
        justify-content: end;

        .toggleIcon {
            transition: transform 0.5s;
            transform: rotate(180deg);
        }

        &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}
